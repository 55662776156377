import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'

import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'

function MyImage({ src, className, isLoadOnInteraction, errorSrc, ...rest }) {
  const [url, setUrl] = useState(isLoadOnInteraction ? noImage : src)
  const wasError = useRef(false)
  const { isLoaded } = useOnMouseAndScroll()
  // delete rest.layout
  useEffect(() => {
    if (isLoadOnInteraction) {
      if (isLoaded) setUrl(src)
      else setUrl(noImage)
    } else if (!wasError.current) {
      setUrl(src)
    }
  }, [src, isLoaded])

  return (
    <Image
      src={url}
      className={`d-block w-100 ${rest?.fill ? '' : 'h-auto'} ${className || ''}`}
      onError={(e) => {
        wasError.current = true
        setUrl(errorSrc || noImage)
      }}
      {...rest}
    />
  )
}
MyImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoadOnInteraction: PropTypes.bool,
  errorSrc: PropTypes.object,
  className: PropTypes.string
}
export default React.memo(MyImage)
